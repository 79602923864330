






















import mixins from 'vue-typed-mixins'
import Navigation from '@/builder/sections/mixins/Navigation'
export default mixins(Navigation).extend({
  name: 'Footer2'
})
